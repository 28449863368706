import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://devapi.qweather.com/v7'
});
// axios.defaults.baseURL = "https://api.heweather.net/s6/weather";
// https://api.qweather.com/v7/ocean/tide // 潮汐
// https://api.qweather.com/v7/weather/now // 实时天气
// https://devapi.qweather.com/v7/warning/now // 天气预警
// https://devapi.qweather.com/v7/grid-weather/now // 天气预警
let key = 'e0e3a33e3a264118815cb4e8813417d8' // dev key
let locationID = '110.3928,21.2228'

/**
 * type: 当天天气及未来3天天气预报
 *      now
 *      72h
 *      3d
 */
export const getWeather = function (type, location) {
  // if (type !== 'now' && type !== 'forecast' && type !== 'lifestyle') throw new Error('getWeather error: type类型错误');
  if (!location) throw new Error('getWeather error: location not defined');
  return instance.get(`/weather/${type}`, {
    params: {
      location,
      key: key
    }
  }).then(function (res) {
    return res.data;
  })
}

export const getWarning = function () {
  return instance.get(`/warning/now`, {
    params: {
      location: locationID,
      key
    }
  }).then(function (res) {
    return res.data;
  })
}

export const initDay = async function () {
  let e = await this.getWeather('now', this.city);
  this.subWeather = e.now.cond_txt;
  this.curTemp = e.now.tmp;
  this.webIcon = e.now.cond_code;
}

export const initDayDetail = async function () {
  let fore = await this.getWeather('forecast', this.city);
  this.foreDays = fore.daily_forecast;
  let life = await this.getWeather('lifestyle', this.city);
  this.lifeStyle = life.lifestyle;
}

export const searchCity = function (location) {
  if (!location) throw new Error('searchCity error: location not defined');
  return instance.get(`now`, {
    params: {
      location,
      key: key
    }
  }).then(function (res) {
    return res;
  })
}
